<template>
  <div class="body">
    <el-table :data="tbody" class="thead-light" stripe style="width: 100%" @sort-change="sortChange"
      v-loading="loading">
      <template v-for="(th, index) in thead">
        <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
          :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
          <template slot-scope="scope">
            <!-- 普通 -->
            <span>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
  </div>
</template>

<script>
import { PositionRecords } from "../../api/member/member-detail";
import Pagination from "@/base/components/Default/Pagination";
export default {
  props: {
    data: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      thead: [
        // { label: "ID", prop: "id", minWidth: 50 },
        { label: "职务记录", prop: "content", minWidth: 600 },
        {
          label: "操作时间",
          prop: "create_time",
          minWidth: 200,
          sortable: true,
        },
      ],
      filterForm: {
        id: this.data?this.$deCode(this.data).id:this.$route.params.user_id,
        page_size: 15,
      },
      tbody: [],
      pageData: {},
    };
  },
  methods: {
    // 获取我的企业列表
    getPositionRecords(pageData) {
      this.loading = true;
      PositionRecords(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getPositionRecords(this.filterForm);
    },
    // 分页查询
    changPage(e) {
      console.log(e);
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getPositionRecords(pageData);
    },
  },
  created() {
    this.getPositionRecords(this.filterForm);
  },
  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.body {
  min-height: calc(100vh - 270px);
}
</style>
